import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./accountReducer";
import loaderReducer from "./loaderReducer";
import alertReducer from "./alertReducer";

export const store = configureStore({
    reducer: {
        account: accountReducer,
        loader: loaderReducer,
        alert: alertReducer,
    },
})