export const serviceUrls = {
    validateToken: '/validateJWT',
    thirdPartyValidate: 'https://backend.gvishnu.in/thirdPartyUse/verifyAuthToken',
    login: '/login',
    createUser: '/createUser',
    verifyCode: '/verify',
    getUserIdByEmailId: '/getUserIdByEmailId',

    // virtual friend chat
    deepSeekAI: '/deepseek',

    // 3rd party URLs
    getIP4: 'https://api.ipify.org/?format=json',
    getIP6: 'https://api64.ipify.org/?format=json',
}