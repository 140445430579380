import { getIPv4URL, serverURL } from "./constant";

export function isFunction( dataType ) {
    return typeof dataType == 'function';
}

export async function getIPv4() {
    const url = getIPv4URL;
    return new Promise((resolve, reject) => {
        fetch(url)
        .then((response) => response.json())
        .then((data) => resolve(data.ip))
        .catch((error) => resolve("err"));
    })
}

export const chatHistory = {
    saveVirtualChat: ( chatHistory ) => {
        sessionStorage.setItem('VirtualChat', JSON.stringify(chatHistory));
    },
    clearVirtualChat: () => sessionStorage.removeItem("VirtualChat"),
    getVirtualChat: () => JSON.parse(sessionStorage.getItem("VirtualChat") ?? '[]'),
}

// export const TransitionWrapper = ({ startTransition, children }) => {
//     startTransition(() => {}); // Start transition for each route change
//     return children;
// };