import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
    loaderCount: 0,
}

export const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            if(action.payload == true) {
                state.loaderCount++;
            } else if(state.loaderCount > 0) {
                state.loaderCount--;
            }
        }
    }
})

export const loaderActions = loaderSlice.actions;

export default loaderSlice.reducer;

export const useSetLoader = () => {
    const loaderCount = useSelector(state => state.loader.loaderCount);
    const dispatch = useDispatch();
    return [loaderCount, ( value ) => {
        dispatch(loaderActions.setLoader(value));
    }]
}