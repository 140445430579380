import React, { lazy, Suspense } from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import LoginModal from "../components/LoginModal";
import { NavLink } from "react-router-dom";
import Loader from "../components/Loader";
import { Provider } from "react-redux";
import { store } from "../AppStore/store";
import { useAccount } from "../AppStore/accountReducer";
import Alert from "../components/Alert";
import VirtualFriend from "./VirtualFriend";
// import LocalNetwork from './LocalNetwork';
const LocalNetwork = lazy(() => import('./LocalNetwork'));
// import Email2Email from "./Email2Email";
const Email2Email = lazy(() => import('./Email2Email'));
// import GroupChat from "./GroupChat";
const GroupChat = lazy(() => import('./GroupChat'));

function App() {

  // useEffect(() => {})

  return (
    <Provider store={store} >
    <BrowserRouter>
      <Alert />
      <Loader />
      <LoginModal />
      <Suspense fallback={<></>} >
      <Routes>
        <Route path='/' element={
          <Home />
        }/>
        <Route path='/localnetwork' element={
          <LocalNetwork />
        }/>
        <Route path='/email2email' element={
          <Email2Email />
        }/>
        <Route path='/groupchat' element={
          <GroupChat />
        }/>
        <Route path='/virtual_friend' element={
          <VirtualFriend />
        }/>
        <Route path='*' element={
          <h2>Page Not Found</h2>
        }/>
      </Routes>
      </Suspense>
    </BrowserRouter>
    </Provider>
  );
}

export default App;


const Home = () => {
  const [accountState, accountDispatcher] = useAccount();

  return (
    <div className="mainMenu text-center">
      <p className="h1">Hey, Welcome to ChatHere</p>
      <p className="h3 text-secondary">Choose chat-type to begin</p>
      <NavLink className="text-white bg-dark chatOptions mt-5" to="/virtual_friend" title="">Virtual Friend</NavLink>
      <NavLink className="text-white bg-dark chatOptions" to="/localnetwork" title="">Local Network</NavLink>
      <NavLink className="text-white bg-dark chatOptions" to="/email2email" title="">Email 2 Email</NavLink>
      <NavLink className="text-white bg-dark chatOptions" to="/groupchat" title="">Group Chat</NavLink>
      {accountState.accessToken?.length ? <p className="mt-5 h4 text-secondary" title="">Wanna switch your account? <a href="#login" 
        onClick={() => {
          localStorage.clear();
          accountDispatcher.logOut()
        }}>LOGOUT</a></p> : ""}
    </div>
  )
}