import React, { useEffect, useState } from "react";  
import { NavLink } from "react-router-dom";
import { io } from 'socket.io-client';
import { serverURL } from "../commLibs/constant";
import { useAccount } from "../AppStore/accountReducer";
import { chatHistory, getIPv4 } from "../commLibs/localLibrary";
import { useSetAlert } from "../AppStore/alertReducer";
import { useSetLoader } from "../AppStore/loaderReducer";
import { commonInvoke } from "../networks/serviceCaller";
import { serviceUrls } from "../networks/serviceUrls";
import ChatListing from "../components/ChatListing";
import MessageInputBar from "../components/MessageInputBar";

export default function VirtualFriend() {
    const [chatObjectList, setchatObjectList] = useState([]);
    const [memberList, setmemberList] = useState([]);
    const [accountState, accountDispatcher] = useAccount();
    const socket = io(serverURL, { autoConnect: false });
    const [mySocket, setmySocket] = useState("");
    const [rowCount, setRowCount] = useState(1);
    const setAlert = useSetAlert();
    const [loaderCount, setLoader] = useSetLoader();

    useEffect(() => {
        if(chatObjectList?.length == 0) setchatObjectList(chatHistory.getVirtualChat());
    }, [])

    useEffect(() => {
        const chatBody = document.getElementById("chatBody")
        const chatInput = document.getElementById("messageInput")
        chatBody.scrollTo(0, chatBody.scrollHeight)
        chatInput.focus()
    }, [chatObjectList])

    const onSubmit = ( value ) => {
        // Send msg
        const newChatLog = [
            ...chatObjectList,
            {
                "role": "user",
                "content": value,
                "createdOn": Date.now(),
                "senderName": accountState?.name
            }
        ]
        setLoader(true);
        commonInvoke("POST", serviceUrls.deepSeekAI, newChatLog?.map(ele => ({ ...ele, "createdOn": undefined, "senderName": undefined })) )
        .then(res => {
            console.log("deepSeekAI res", res)
            if(res.data?.aiRes) updatedChatHistory([
                ...newChatLog,
                {
                    "role": "assistant",
                    "content": res.data?.aiRes,
                    "createdOn": Date.now(),
                    "senderName": "Virtual Friend"
                }
            ]);
        })
        .catch(err => {
            setAlert({
                msg: "Something went wrong Please try again after sometime!",
                type: "error"
            })
            console.log("deepSeekAI err", err);
        })
        .finally(() => setLoader(false));
        updatedChatHistory(newChatLog);
    }

    const updatedChatHistory = ( prevChatHistory ) => {
        setchatObjectList(prevChatHistory);
        chatHistory.saveVirtualChat(prevChatHistory);
    }

    const resetChat = () => {
        setchatObjectList([]);
        chatHistory.clearVirtualChat();
    }

    console.log("fdsgsdg", chatObjectList);

    return (
        <div id="localnetwork">
            <div className="header d-flex justify-content-between text-white bg-dark">
                <NavLink to="../">Go Back</NavLink>
                <div className="title">Your Virtual Friend Chat</div>
                <div className="cursor-pointer" title="Reset" onClick={resetChat}>&#x21BA;</div>
            </div>
            <ChatListing callerRef={"VirtualFriend"} chatObjectList={chatObjectList} />
            <MessageInputBar onSubmit={onSubmit} />
        </div>
    )
}