import axios from "axios";
import { serverURL } from "../commLibs/constant";

axios.defaults.baseURL = serverURL;

export let cancelPrevInvoke;
export function commonInvoke (method, url, body) {
  try {
    return axios({
      cancelToken: new axios.CancelToken((c) => {
        cancelPrevInvoke = c;
      }),
      method: method,
      url: url,
      data: method === "POST" || method === "PUT" || method === "DELETE" ? body : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.error("Request canceled ", error.message);
    } else {
      console.error("Something went wrong ", error.message);
    }
  }
};