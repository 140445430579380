import { useState } from "react";

export default function MessageInputBar ({ onSubmit }) {
    const [innerText, setInnerText] = useState('');

    const handleSendMsg = ( e ) => {
        e.preventDefault();
        if(innerText.trim()) onSubmit(innerText.trim());
        
        // clearInput
        document.getElementById("messageInput").innerHTML = '';
        setInnerText('');
    }

    return <>
    <div className="inputBar d-flex bg-light justify-content-between text-center">
        <form 
            onSubmit={handleSendMsg}
            className="d-flex align-content-end  text-white bg-dark justify-content-between w-100"
        >
            <div id="messageInput" name="messageInput" className="w-75"  
                onKeyDown={(e) => {
                    if(e.key == "Enter" && e.shiftKey == false) {
                        handleSendMsg(e);
                        return false;
                    }
                    return true;
                }}
                onKeyUp={(e) => setInnerText(e.target.innerText)}
                contentEditable 
            ></div>
            {(innerText?.length == 0 || innerText == "\n") && <span className="customPlaceholder" >Enter your text</span>}
            <button className="w-25" type="submit">Send</button>
        </form>
    </div>
    </>
}