export const demo = false

export const serverURL = demo == true ? 'http://localhost:8080' : 'https://backend.gvishnu.in';

export const regex4Email = /^[\w\-\.]+@([\w-]+\.)+[a-z]{2,}$/;

export const getIPv4URL = 'https://api.ipify.org?format=json';

export const unifiedLoginSystemPublicKey = "3eeeeb1c-a490-4524-a32b-7936cf26eaf7";

export const chatCaution = [
    "Make sure your opponent connected before you start chatting",
    "Your chats are stored only in this tab and disappear when you close it — no servers involved! Feel free to chat away with your virtual friend without a worry in the world!"
]