import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSetAlert } from "../AppStore/alertReducer";

const Alert = () => {
    const alertState = useSelector(state => state.alert);
    const setAlert = useSetAlert();

    useEffect(() => {
        if(alertState.msg?.length) {
            setTimeout(() => setAlert({}), [3000])
        }
    }, [alertState])

    const typeClassName = alertState.type == "success" ? "success" : alertState.type == "error" ? "danger" : alertState.type == "warning" ? "warning" : "";

    return (
        <>
        {alertState?.msg?.length > 0 && <div class={`position-fixed m-2 p-2 px-4 border border-${typeClassName} alert alert-${typeClassName}`} role="alert" style={{ zIndex: '9999'}} >
            {alertState.msg}
        </div>}
        </>
    )
}

export default Alert;