import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
    showAlert: false,
    msg: '',
    type: '',
}

export const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setAlert: (state, action) => {
            state.showAlert = action.payload?.msg?.length > 0 || false;
            state.msg = action.payload?.msg || "";
            state.type = action.payload?.type || "";
        }
    }
})

export const alertActions = alertSlice.actions;

export default alertSlice.reducer;

export const useSetAlert = () => {
    const dispatch = useDispatch();
    return ( value ) => {
        dispatch(alertActions.setAlert(value));
    }
}