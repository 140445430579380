import { chatCaution } from "../commLibs/constant";

export default function ChatListing ({ callerRef, chatObjectList, currentUserId, connectToUserId }) {
    console.log("ChatListing", chatObjectList);
    return (
        <div id="chatBody" className="chatBody bg-light">
            <p className="text-secondary p-2 text-center ">{chatCaution[callerRef == "VirtualFriend" ? 1 : 0]}</p>
            {callerRef == "Email2Email" && <p className="text-secondary p-2 text-center ">Connected to {connectToUserId}</p>}
            {chatObjectList?.map(ele => <ChatInstance callerRef={callerRef} {...ele} key={ele.createdOn} currentUserId={currentUserId} />)}
        </div>
    )
}

const ChatInstance = ( props ) => {
    const { callerRef, senderId, role, content, messageBody, createdOn, senderName, currentUserId } = props
    const timeString = new Date(createdOn)?.toLocaleString()?.split(", ")?.[1];
    const leftClassName = "chatInstance left w-75"
    const rightClassName = "chatInstance right w-75"
    const isMyMsg = callerRef == "VirtualFriend" ? role == "user" : senderId == currentUserId;
    const mainContent = callerRef == "VirtualFriend" ? content : messageBody;

    return (
        <div className={isMyMsg ? rightClassName : leftClassName} key={timeString} >
            <div className="d-flex justify-content-between text-break " style={{color: 'rgb(169, 255, 41)'}}>
                <div>{senderName + (isMyMsg ? " (You)" : "")}</div>
                <div>{timeString}</div>
            </div>
            <div className="chatContent">
                {mainContent?.split('\n')?.map((sentence, index) => <>
                    {sentence}
                    <br/>
                </>)}
            </div>
        </div>
    )
}