import React, { useEffect, useState } from "react";
import { commonInvoke } from "../networks/serviceCaller";
import { serviceUrls } from "../networks/serviceUrls";
import '../index.scss';
import { useDispatch } from "react-redux";
import { useSetLoader } from "../AppStore/loaderReducer";
import { useAccount } from "../AppStore/accountReducer";
import { demo, regex4Email, unifiedLoginSystemPublicKey } from "../commLibs/constant";
import { connectToUnifiedLoginSystem } from 'connect-unified-login-system';
import { useSetAlert } from "../AppStore/alertReducer";

const handleUnifiedLoginSystem = async () => {
    const publicKey = unifiedLoginSystemPublicKey;
    const response = await connectToUnifiedLoginSystem({ publicKey , isProd: demo == false });
    console.log("messageFromULS-response", response);

    const { isError, name, emailId, authToken } = response;
    return { isError, name, emailId, authToken };
}

const LoginModal = (props) => {
    const [needLogin, setneedLogin] = useState(false);
    const [isloginNotRegister, setisloginNotRegister] = useState(true);
    const [loaderCount, setLoader] = useSetLoader();
    const [accountState, accountDispatcher] = useAccount();
    // const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        if(!accountState.accessToken) setneedLogin(true);
        else validateToken(accountState.accessToken);
        // console.log("account", accessToken, accountState, accountDispatcher);
    }, [accountState.accessToken]);


    const validateToken = ( JWToken ) => {
        const url = serviceUrls.thirdPartyValidate;
        const jsonBody = {
            "authToken": JWToken,
            "publicKey": unifiedLoginSystemPublicKey
        }

        setLoader(true)
        commonInvoke("POST", url, jsonBody)
        .then(res => {
            setneedLogin(false);
            console.log("validateToken res", res)
            const userObj = res?.data?.data;
            if(userObj) {
                accountDispatcher.setName(userObj.name)
                accountDispatcher.setEmailId(userObj.emailId)
                accountDispatcher.setUserId(userObj.userId)
                accountDispatcher.setAccessToken(JWToken)
            } else {
                throw new Error("Invalid token")
            }
        })
        .catch(err => {
            accountDispatcher.logOut();
            console.log("validateToken error", err)
        }).finally(() => setLoader(false))
    }

    return (
        <>
        {needLogin == true ?
        <div id="modal" >
            <div className="modal-overlay"></div>
            <div className="modal-content mt-5 align-middle">
                <div className='entryContainer w-100 bg-light'>
                    {/* <div className="entryOption">
                        <a className={`font-weight-bold p-3 ${!isloginNotRegister ? 'bg-light text-dark' : 'bg-secondary text-white'}`}
                            style={{borderRadius: '25px 0 0 0'}} href="#signup" onClick={e => setisloginNotRegister(false)}>SIGN UP</a>
                        <a className={`font-weight-bold p-3 ${isloginNotRegister ? 'bg-light text-dark' : 'bg-secondary text-white'}`} 
                            style={{borderRadius: '0 25px 0 0'}} href="#login" onClick={e => setisloginNotRegister(true)}>LOG IN</a>
                    </div> */}
                    {/* // for signUp */}
                    {/* {(!isloginNotRegister) && <SignUp setToLogin={setisloginNotRegister}/>} */}
                    {/* // for logIn */}
                    {/* {(isloginNotRegister) && <LogIn/>} */}
                    <LogIn />
                </div>
            </div>
        </div>
        : ""}
        </>
    )
}

export default LoginModal;

const LogIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loaderCount, setLoader] = useSetLoader();
    const [accountState, accountDispatcher] = useAccount();
    const setAlert = useSetAlert();

    const userLogin = async () => {
        setLoader(true);
        const { isError, name, emailId, authToken } = await handleUnifiedLoginSystem();
        setLoader(false);
        if(isError) return setAlert({
            msg: "Login Failed! Please try again",
            type: "error"
        })
        
        localStorage.setItem("accessToken", authToken)
        accountDispatcher.setAccessToken(authToken)
        setAlert({
            msg: "Login Success!",
            type: "success"
        })
    }

    const isWrongEmail = regex4Email.test(email) != true;
    const isWrongPassword = (password?.length <= 10 && password?.length >= 8) != true;

    return (
    <form>
        {/* <div className='loginInput'>
            <label>Email Id</label>
            <input className={isWrongEmail ? `redOutLine` : ''} type="email" placeholder=' Enter Email ID ' value={email} onChange={e => {
                const _email = e.target.value;
                if(_email?.length <= 50) setEmail(_email)
            }} autoComplete="off"/>
        </div>
        <div className='loginInput'>
            <label>Password</label>
            <input className={isWrongPassword ? `redOutLine` : ''} type="password" placeholder=' Enter Password ' value={password} onChange={e => {
                const _password = e.target.value;
                if(_password.length <= 10) setPassword(_password)
            }} autoComplete="off"/>
        </div>
        <input className="font-weight-bold bg-secondary text-white border-0 p-2" type="button" value="LOGIN" onClick={userLogin}/>
        <hr /> */}
        <h3>Hold up, chatterbox!</h3>
        <p className="m-0">You gotta log in first before you can drop those fire messages! 🔥💬</p>
        <p className="m-0 mb-3">Click below and let’s get you talking! 😎👇</p>
        {/* <label className="font-weight-bold p-2" >Proceed to login</label> */}
        <input className="font-weight-bold bg-secondary text-white border-0 p-2" type="button" value="Login with Unified Login System" onClick={userLogin}/>
    </form>
)}